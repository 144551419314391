<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Procesos de Calidad </v-card-title>
      <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        ><CIcon name="cil-user" /> Nuevo</CButton
      >

      <v-card-text>
        <div class="text-center" v-if="loadingTiposOperacion">
          <div class="spinner-border" role="status"></div>
        </div>
        <filters
          v-if="!loadingTiposOperacion"
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>
        <div>
          <v-alert
            v-if="!permiteEjecutar"
            v-model="alert"
            border="left"
            close-text="Close Alert"
            type="info"
            dark
            dismissible
          >
            Se están ejecutando procesos.  Una vez finalicen estarán disponibles las opciones de ejecución.
          </v-alert>
        </div>
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="items"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
          <template #activo="data">
            <td>
              <CBadge :color="data.item.activo ? 'success' : 'secondary'">
                {{ data.item.activo == 1 ? "Habilitado" : "Inhabilitado" }}
              </CBadge>
            </td>
          </template>
          <template #acciones="data">
            <td>
              <CLink
                href="#"
                @click="pasos(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Pasos'"
              >
                <CIcon name="cil-list" />
              </CLink>
              <CLink
                href="#"
                @click="editar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Editar'"
              >
                <CIcon name="cil-pencil" />
              </CLink>
              &nbsp;<span
                v-if="data.item.loadingEjecutar"
                class="spinner-border spinner-border-sm"
                role="status"
              ></span>
              <CLink
                v-if="permiteEjecutar"
                v-show="!data.item.loadingEjecutar"
                href="#"
                @click="ejecutar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Ejecutar'"
              >
                <CIcon name="cil-check" />
              </CLink>
              <CLink
                href="#"
                @click="ejecuciones(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Ejecuciones'"
              >
                <CIcon name="cil-list" />
              </CLink>
              <CLink
                href="#"
                @click="preEliminar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Eliminar'"
              >
                x
              </CLink>
            </td>
          </template>
        </CDataTable>
      </v-card-text>
    </v-card>
    <br /><br />
    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea eliminar el registro?
        </v-card-title>

        <v-card-text>
          {{deleteItem?deleteItem.id:''}} - {{deleteItem?deleteItem.nombre:''}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn color="gray darken-1" text @click="eliminar"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Filters from "@/components/filters";
import ProcesoCalidadService from "@/services/procesoCalidad.service";
import EjecucionProcesoCalidadService from "@/services/ejecucionProcesoCalidad.service";
import ArchivoService from "@/services/archivo.service";
import ListaService from "@/services/lista.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Users",
  data() {
    return {
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "nombre", label: "Nombre", _classes: "font-weight-bold" },
        { key: "descripcion", label: "Descripción" },
        { key: "tipo_operacion_nombre", label: "Tipo de Operación" },
        { key: "activo", label: "Estado" },
        { key: "acciones", label: "Acciones" },
      ],
      filterFields: [
        { value: "id", label: "Código" },
        { value: "nombre", label: "Nombre" },
        {
          value: "tipo_operacion_id",
          label: "Tipo de Operación",
          //TODO: Traer desde Base de Datos!
          options: [
            { id: 61, name: "Asociada Tercerizada" },
            { id: 62, name: "Asociada Operada" },
            { id: 63, name: "Tercerizada" },
          ],
        },
        {
          value: "activo",
          label: "Estado",
          options: [
            { id: 1, name: "Habilitado" },
            { id: 0, name: "Inhabilitado" },
          ],
        },
      ],
      activePage: 1,
      loading: false,
      loadingTiposOperacion: false,
      ejecucionesPendientes: undefined,
      archivosPendientes: undefined,
      permiteEjecutar: true,
      alert: false,
      message: "",
      dialog: false,
      deleteItem: undefined,
    };
  },
  components: {
    Filters,
  },
  watch: {
    "ejecucionesPendientes"(newVal) {
      if(this.ejecucionesPendientes != undefined && this.archivosPendientes != undefined) {
        if(!this.ejecucionesPendientes.length && !this.archivosPendientes.length) {
          this.permiteEjecutar = true
        } else {
          this.alert= true
        }
      }
    },
    "archivosPendientes"(newVal) {
       if(this.ejecucionesPendientes != undefined && this.archivosPendientes != undefined) {
        if(!this.ejecucionesPendientes.length && !this.archivosPendientes.length) {
          this.permiteEjecutar = true
        } else {
          this.alert= true
        }
      }
    },
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Definición de proceso de calidad"))
      this.$router.push("/dashboard");
    this.fetchItems("");
    // this.fetchEjecucionesPendientesProcesosCalidad();
    // this.fetchArchivosPendientes();
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda);
    });

    this.fetchTiposOperacion("");
  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      ProcesoCalidadService.getItems(cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          this.items = this.items.map(function f(x) {
            let tipo_operacion = x.tipo_operacion
              ? x.tipo_operacion.valor_texto
              : null;
            return { ...x, tipo_operacion_nombre: tipo_operacion, loadingEjecutar: false };
          });
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    fetchEjecucionesPendientesProcesosCalidad() {
      EjecucionProcesoCalidadService.getEjecucionesPendientes().then(
        (response) => {
          this.ejecucionesPendientes = response.data;
          console.log('ejecuciones en curso...')
          console.log(response.data);
        },
        (error) => { 
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    fetchArchivosPendientes() {
      ArchivoService.getArchivosPendientes().then(
        (response) => {
          this.archivosPendientes = response.data;
          console.log('arvhivos en curso...')
          console.log(response.data);
        },
        (error) => { 
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    fetchTiposOperacion(cadenaBusqueda) {
      this.loadingTiposOperacion = true;
      ListaService.getTiposOperacion().then(
        (response) => {
          this.filterFields[2].options = response.data.data;
          this.filterFields[2].options = this.filterFields[2].options.map(
            function f(x) {
              return { ...x, name: x.valor_texto };
            }
          );
          this.loadingTiposOperacion = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingTiposOperacion = false;
        }
      );
    },
    crear() {
      localStorage.removeItem("datos_proceso_calidad");
      this.$router.push({ path: `procesos-calidad/editar/` });
    },
    editar(item) {
      localStorage.setItem("datos_proceso_calidad", JSON.stringify(item));
      this.$router.push({ path: `procesos-calidad/editar/` });
    },
    pasos(item) {
      localStorage.setItem("datos_proceso_calidad", JSON.stringify(item));
      this.$router.push({ path: `procesos-calidad/pasos` });
    },
    ejecutar(item) {
      localStorage.setItem("datos_proceso_calidad", JSON.stringify(item));
      this.$router.push({ path: `procesos-calidad/ejecutar/` });
    },
    ejecuciones(item) {
      localStorage.setItem("datos_proceso_calidad", JSON.stringify(item));
      this.$router.push({ path: `procesos-calidad/ejecuciones` });
    },
    preEliminar(item) {
      this.deleteItem = item;
      this.dialog = true;
    },
    eliminar() {
      this.loading = true;
      this.dialog = false;
      ProcesoCalidadService.deleteItem(this.deleteItem.layout_id, this.deleteItem.id).then(
          (response) => {
            console.log(response.data);
            this.fetchItems("");
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
